import { useAuth } from '@on3/ui-lib/src/contexts/AuthProvider';
import { useSite } from '@on3/ui-lib/src/contexts/SiteProvider';
import clsx from 'clsx';

import styles from './MPU.module.scss';

interface IAdProps {
  id?: string;
  className?: string;
  incontent?: boolean;
}

export const MPU = ({ id, className, incontent = false }: IAdProps) => {
  const { user } = useAuth();
  const { currentSite } = useSite();
  const isFeed = currentSite?.template === 'Feed';

  if (user?.st?.includes('premium')) {
    return null;
  }

  if (incontent && user?.has && !isFeed) {
    return null;
  }

  return (
    <div
      className={clsx(
        styles.ad,
        incontent && styles.incontent,
        className,
        'mpuAd',
      )}
      data-ui="ad"
      id={id}
    />
  );
};
